.sanitized-html > p {
  margin: 0;
}

.sanitized-html > ul {
  padding-left: 0.5rem;
  margin: 0;
  list-style-position: inside;
  word-break: break-word;
}

.sanitized-html > ul > li {
  display: list-item !important;
  list-style-type: disc !important;
}

.sanitized-html__html {
  white-space: initial !important;
}
